.ql-toolbar-disable > .ql-toolbar {
  display: none !important;
}

.ql-container.ql-snow {
  height: auto;
}

.ql-editor {
  height: 75vh;
  overflow-y: scroll;
}
